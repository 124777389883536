import React, { useEffect, useState ,useRef , useMemo} from 'react';
// import PIService from '../../services/product.service';
import SecurityService from '../security/security.service';
import './PI.scss';
import HardDelete from '../hard-delete/hard-delete';
import CheckDuplicate  from '../check-duplicate/check-duplicate';
import AuditTableInput from '../audit-table/audit-table';
import { FaEnvelope } from 'react-icons/fa';
import TemplateCode from '../template-code/template-code';
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
   
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import PIAdd from './PI-add';
// import PIEdit from './PI-edit';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
import {image} from './image';
import PIMessageButtonList from '../messages-button-list/messages-button-list';

import logoUrl from './logo192.png';
import PIService from '../../services/PI.service';
import EmailEditorPopup from './email-editor-popup';
import imgSrc from '../upload-supplier/spinner.gif';
import '@fortawesome/fontawesome-free/css/all.css';
import { useDispatch, useSelector } from 'react-redux';
import { gridColumnsList } from '../../store/slices/list-tempate'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import UploadPdfFile from '../upload-pdf-files/upload-pdf-files-list';

let ProformaInvoiceGrid: Grid;
var selectedRows = []
var setPIID = ''
export default function ProformaInvoiceList(props) {
  // Default Column name and its detail
  const commands: CommandModel[] = [
    {
      buttonOption: { id: 'email', content: 'E', cssClass: 'e-flat', isPrimary: true, },
    },
    {
      buttonOption: { id: 'message', content:  'M', cssClass: 'e-flat', isPrimary: true, },
    },
    {
      buttonOption: { id: 'uploadPDF', content:  'A', cssClass: 'e-flat', isPrimary: true, },
    }
]
  const formatOptions = { type: 'date', format: 'dd/MM/yy' }
  let getpaths =   window.location.pathname
    .substr(1, window.location.pathname.length)
    .split('/');
    
    
    // Supplier
    // Sales Agent
    // Bank Account
    // Sender Name
    // Currency
    // TT Amount
    // Ref no
    // Invoice no
    // Allocation amount
    // Procedure (BL HOLD/RELEASE/Surrender)
    // CRM Status Date
    // CRM Name
    // Adjustment
    // Remarks
    // let ColumnProps =[
    //   {  field: "Commands",headerText:'Commands',width:'180',textAlign:'center' ,headerTextAlign:'center',other:[{ commands: commands}]},
    //   {  field: "SupplierName",headerText:'Supplier',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //   {  field: "SalesAgentName",headerText:'Sales Agent',width:'130',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //   {  field: "PaymentReferenceNo",headerText:'Bank Account',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //   {  field: "PaymentSenderName",headerText:'Sender Name',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //   {  field: "CurrencyName",headerText:'Currency',width:'150',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //   {  field: "PaymentDescription",headerText:'TT Amount',width:'140',textAlign:'Right' ,headerTextAlign:'center',other:[]},
    //   {  field: "PIReferenceNo",headerText:'PI Reference No',width:'120',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "PINo",headerText:'PI No',width:'110',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //   {  field: "ReceiptAmount",headerText:'Receipt Amount',width:'130',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    //   {  field: "SalesAgentStatus",headerText:'Sales Agent Status',width:'145',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //   {  field: "CRMStatusDate",headerText:'CRM Status Date',width:'145',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    //   {  field: "CRMName",headerText:'CRM Name',width:'145',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //   {  field: "AdjustmentAmount",headerText:'Adjustment Amount',width:'165',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    //   {  field: "Remarks",headerText:'Remarks',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]}

    // ]
    console.log('getpaths ' , getpaths)
    let ColumnProps = getpaths[2] == 'CRM' ?
    [
// Supplier
// Sales Agent
// Bank Account
// Sender Name
// Currency
// TT Amount
// Ref no
// Invoice no
// Allocation amount
// Procedure (BL HOLD/RELEASE/Surrender)(Sales Agent Status)
// CRM Status Date
// CRM Name
// Adjustment
// Remarks
// Booking Amount
// CRM Status
// Consignee
// Trade Board Letter
// Serial No
// Customer
// Confirm Consignee
// Country
// City
// Port
// Shipment Type
// Purchase Source
// Creation Date
// Username
// Vessel Name
// Voyage No
// ETD
// ETA
// Short Amount
// Sales Agent Status Date
// Payment Verification Status
// Payment Verification Status Date
// ID
    {  field: "Commands",headerText:'Commands',width:'180',textAlign:'center' ,headerTextAlign:'center',other:[{ commands: commands}]},
    {  field: "SupplierName",headerText:'Supplier',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "SalesAgentName",headerText:'Sales Agent',width:'130',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "PaymentReferenceNo",headerText:'Bank Account',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "PaymentSenderName",headerText:'Sender Name',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "CurrencyName",headerText:'Currency',width:'150',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "PaymentDescription",headerText:'TT Amount',width:'140',textAlign:'Right' ,headerTextAlign:'center',other:[]},
    {  field: "PIReferenceNo",headerText:'PI Reference No',width:'120',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "PINo",headerText:'PI No',width:'110',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "ReceiptAmount",headerText:'Receipt Amount',width:'130',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    {  field: "SalesAgentStatus",headerText:'Sales Agent Status',width:'145',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "CRMStatusDate",headerText:'CRM Status Date',width:'145',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    {  field: "CRMName",headerText:'CRM Name',width:'145',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "AdjustmentAmount",headerText:'Adjustment Amount',width:'165',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    {  field: "Remarks",headerText:'Remarks',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "BookAmount",headerText:'Booking Amount',width:'135',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    {  field: "CRMStatus",headerText:'CRM Status',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "ConsigneeName",headerText:'Consignee',width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "TradeBoardLetterStatus",headerText:'Trade Board Letter',width:'145',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "SerialNo",headerText:'Serial No',width:'80',textAlign:'Right' ,headerTextAlign:'center',other:[]},
    {  field: "CustomerName",headerText:'Customer',width:'150',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "ConfirmConsigneeName",headerText:'Confirm Consignee',width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "CountryName",headerText:'Country',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "CityName",headerText:'City',width:'120',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "PortName",headerText:'Port',width:'120',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "ShipmentType",headerText:'Shipment Type',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "PurchaseSource",headerText:'Purchase Source',width:'150',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "CreationDate",headerText:'Creation Date',width:'140',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    {  field: "UserName",headerText:'User Name',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "VesselName",headerText:'Vessel Name',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "VoyageNo",headerText:'Voyage No',width:'130',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "ETD",headerText:'ETD',width:'120',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    {  field: "ETA",headerText:'ETA',width:'120',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    {  field: "ShortAmount",headerText:'Short Amount',width:'130',textAlign:'Right' ,headerTextAlign:'center',other:[]},
    {  field: "SalesAgentStatusDate",headerText:'Sales Agent Status Date',width:'140',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},

    {  field: "PaymentVerificationStatus",headerText:'Payment Verification Status',width:'175',textAlign:'Left' ,headerTextAlign:'center',other:[{
        'template': (props) => {
          return (
            <div className={props.PaymentVerificationStatus === "PENDING VERIFICATION" ? `pendingPayment` : ''}>
              {props.PaymentVerificationStatus}
            </div>
          );
        }
       }]},

     {  field: "PaymentVerificationStatusDate",headerText:'Payment Verification Status Date',width:'175',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
     {  field: "ProformaInvoicePaymentDetailID",headerText:'ID',width:'175',textAlign:'Center' ,headerTextAlign:'center',other:[]},





      // ******** COMMENT ON 12/11/2024 DATE  ASIF BHAI WANT CHANGE COLUMNS FIELDS BELOW CODE COMMENT FOR BACKUP ******
      //  {  field: "Commands",headerText:'Commands',width:'180',textAlign:'center' ,headerTextAlign:'center',other:[{ commands: commands}]},
    //  {  field: "SalesAgentName",headerText:'Sales Agent',width:'130',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "PaymentReferenceNo",headerText:'Bank Account',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "PaymentSenderName",headerText:'Sender Name',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "PaymentDescription",headerText:'TT Amount',width:'140',textAlign:'Right' ,headerTextAlign:'center',other:[]},
    //  {  field: "PIReferenceNo",headerText:'PI Reference No',width:'120',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "PINo",headerText:'PI No',width:'110',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "CurrencyName",headerText:'Currency',width:'150',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "BookAmount",headerText:'Booking Amount',width:'135',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    //  {  field: "ReceiptAmount",headerText:'Receipt Amount',width:'130',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    //  {  field: "CRMStatus",headerText:'CRM Status',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "ConsigneeName",headerText:'Consignee',width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "TradeBoardLetterStatus",headerText:'Trade Board Letter',width:'145',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "CRMStatusDate",headerText:'CRM Status Date',width:'145',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    //  {  field: "CRMName",headerText:'CRM Name',width:'145',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "SerialNo",headerText:'Serial No',width:'80',textAlign:'Right' ,headerTextAlign:'center',other:[]},
    //  {  field: "SupplierName",headerText:'Supplier',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "CustomerName",headerText:'Customer',width:'150',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "ConfirmConsigneeName",headerText:'Confirm Consignee',width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "CountryName",headerText:'Country',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "CityName",headerText:'City',width:'120',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "PortName",headerText:'Port',width:'120',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "ShipmentType",headerText:'Shipment Type',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "AdjustmentAmount",headerText:'Adjustment Amount',width:'165',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    //  {  field: "PurchaseSource",headerText:'Purchase Source',width:'150',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "Remarks",headerText:'Remarks',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "CreationDate",headerText:'Creation Date',width:'140',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    //  {  field: "UserName",headerText:'User Name',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "VesselName",headerText:'Vessel Name',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "VoyageNo",headerText:'Voyage No',width:'130',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "ETD",headerText:'ETD',width:'120',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    //  {  field: "ETA",headerText:'ETA',width:'120',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    //  {  field: "ShortAmount",headerText:'Short Amount',width:'130',textAlign:'Right' ,headerTextAlign:'center',other:[]},
    //  {  field: "SalesAgentStatus",headerText:'Sales Agent Status',width:'145',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    //  {  field: "SalesAgentStatusDate",headerText:'Sales Agent Status Date',width:'140',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    //  {  field: "PaymentVerificationStatus",headerText:'Payment Verification Status',width:'175',textAlign:'Left' ,headerTextAlign:'center',other:[{
    //   'template': (props) => {
    //     return (
    //       <div className={props.PaymentVerificationStatus === "PENDING VERIFICATION" ? `pendingPayment` : ''}>
    //         {props.PaymentVerificationStatus}
    //       </div>
    //     );
    //   }
    //  }]},
    //  {  field: "PaymentVerificationStatuss",headerText:'Payment Verification Status',width:'175',textAlign:'Left' ,headerTextAlign:'center',other:[{'visible':false,
    //   'template': (props) => {
    //     return (
    //       <div className={props.PaymentVerificationStatus === "PENDING VERIFICATION" ? `pendingPayment` : ''}>
    //         {props.PaymentVerificationStatus}
    //       </div>
    //     );
    //   }
    //  }]},
    //  {  field: "PaymentVerificationStatusDate",headerText:'Payment Verification Status Date',width:'175',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    //  {  field: "ProformaInvoicePaymentDetailID",headerText:'ID',width:'175',textAlign:'Center' ,headerTextAlign:'center',other:[]},
     
  ] :
  [
    {  field: "Commands",headerText:'Commands',width:'180',textAlign:'center' ,headerTextAlign:'center',other:[{ commands: commands}]},
    {  field: "PIID",headerText:'ID',width:'100',textAlign:'Left' ,headerTextAlign:'center',other:[{'visible':false }]},
    {  field: "SerialNo",headerText:'Serial No',width:'70',textAlign:'Right' ,headerTextAlign:'center',other:[]},
    {  field: "PINo",headerText:'PI No',width:'110',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "PIReferenceNo",headerText:'PI Reference No',width:'120',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "SupplierName",headerText:'Supplier',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "CustomerName",headerText:'Customer',width:'150',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "SalesAgentName",headerText:'Sales Agent',width:'130',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "ConsigneeName",headerText:'Consignee',width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "ConfirmConsigneeName",headerText:'Confirm Consignee',width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "CommissionAmountPerUnit",headerText:'Commission Amt Per Unit',width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[{'visible':false , 'format':'n0'}]},
    {  field: "ApproveCommissionAmountPerUnit",headerText:'Approve Commission Amt Per Unit',width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[{'visible':false }]},
    {  field: "AbsoluteCommissionAmount",headerText:"Commission Absolute Comm Amt",width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[{'visible':false , 'format':'n0'}]},
    {  field: "ApproveAbsoluteCommissionAmount",headerText:"Approve Commission Absolute Comm Amt",width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[{'visible':false }]},
    {  field: "CountryName",headerText:'Country',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "CityName",headerText:'City',width:'120',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "PortName",headerText:'Port',width:'120',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "ShipmentType",headerText:'Shipment Type',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "BookAmount",headerText:'Booking Amount',width:'135',textAlign:'Right' ,headerTextAlign:'center',other:[{ 'format':'n0'}]},
    {  field: "CurrencyName",headerText:'Currency',width:'150',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "PrimaryCommissionPercentOnAdjustment",headerText:"Primary Comm % On Adjustment",width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[{'visible':false }]},
    {  field: "SecondaryCommissionPercentOnAdjustment",headerText:"Secondary Comm % On Adjustment",width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[{'visible':false }]},
    {  field: "ApproveSecondaryCommissionPercentOnAdjustment",headerText:"Approve Secondary Comm % On Adjustment",width:'155',textAlign:'Left' ,headerTextAlign:'center',other:[{'visible':false }]},
    {  field: "AdjustmentAmount",headerText:'Adjustment Amount',width:'165',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    getpaths[2] == 'Supervisor' ? {  field: "BasePriceAgent",headerText:'Base Price Agent',width:'165',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]} : {},
    getpaths[2] == 'Supervisor' ? {  field: "BasePriceCompany",headerText:'Base Price Company',width:'165',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]} : {},
    {  field: "PurchaseSource",headerText:'Purchase Source',width:'150',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "TradeBoardLetterStatus",headerText:'Trade Board Letter',width:'145',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "Remarks",headerText:'Remarks',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "CreationDate",headerText:'Creation Date',width:'140',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    {  field: "UserName",headerText:'User Name',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "VesselName",headerText:'Vessel Name',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "VoyageNo",headerText:'Voyage No',width:'130',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "ETD",headerText:'ETD',width:'120',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    {  field: "ETA",headerText:'ETA',width:'120',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    {  field: "ReceiptAmount",headerText:'Receipt Amount',width:'130',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    {  field: "ShortAmount",headerText:'Short Amount',width:'130',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    {  field: "UnApprovedCRMAmount",headerText:'UnApproved CRM Amount',width:'130',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
    {  field: "SalesAgentStatus",headerText:'Sales Agent Status',width:'145',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "SalesAgentStatusDate",headerText:'Sales Agent Status Date',width:'140',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    {  field: "PaymentVerificationStatus",headerText:'Payment Verification Status',width:'175',textAlign:'Left' ,headerTextAlign:'center',other:[{
      'template': (props) => {
       return (
         <div className={props.PaymentVerificationStatus === "PENDING VERIFICATION" ? `pendingPayment` : ''}>
           {props.PaymentVerificationStatus}
         </div>
       );
     }
    }]},
    {  field: "PaymentVerificationStatuss",headerText:'Payment Verification Status',width:'175',textAlign:'Left' ,headerTextAlign:'center',other:[{'visible':false,
      'template': (props) => {
        return (
          <div className={props.PaymentVerificationStatus === "PENDING VERIFICATION" ? `pendingPayment` : ''}>
            {props.PaymentVerificationStatus}
          </div>
        );
      }
     }]},
    {  field: "PaymentVerificationStatusDate",headerText:'Payment Verification Status Date',width:'175',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
    {  field: "CRMName",headerText:'CRM Name',width:'145',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "CRMStatus",headerText:'CRM Status',width:'140',textAlign:'Left' ,headerTextAlign:'center',other:[]},
    {  field: "CRMStatusDate",headerText:'CRM Status Date',width:'145',textAlign:'Right' ,headerTextAlign:'center',other:[{'format':formatOptions, 'type':'date'}]},
   ]


// InputL ist Security Name
let securityList =   window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');
    console.log(securityList)
    
var InputListSecurity =`PI${securityList[2]}List`
let defaultSortColumnName = 'SerialNo'
let defaultSortColumnType = 'Descending'
let InputNameForPdfExcelExport = 'PI - LIST'
let exportFileName = 'PI_information'
  console.log(props)
  
    // var img =  imagess;
    const dispatch = useDispatch()
    const reduxData = useSelector(state =>{return state.listTemplate})
    var filterColumnName = []
    var recordColumnName = []
    var fiterCheckedValue = []
  const [paramsType, setParamsType] = useState();
  const [dataSourceState, setDataSourceState] = useState([]);
  const [deletingProps, setDeletingProps] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogs, setShowDialogs] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [printedByUserName, setPrintedByUserName] = useState();
  const [printedByEmail, setPrintedByEmail] = useState();
  const [showDialogEmail, setShowDialogEmail] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [piisLoading, setPiisLoading] = useState(true);

  useEffect(() => {
    let filteredArr = ColumnProps.filter(obj => Object.keys(obj).length !== 0);
    dispatch(gridColumnsList(filteredArr))
    setTimeout(() => {
      console.log(reduxData)
    }, 1000);
    
    // document.getElementById('ProformaInvoiceGrid_content_table').style.width = 'auto !important'
  }, []);
  
  // DYNAMIC TEMPLATE CODE
  const [isToggled, setIsToggled] = useState(true);
  const [ToggledValue, setToggledValue] = useState( 'Hide Template');
  useEffect(() => {
    setTimeout(() => {
      setToggledValue(isToggled == true? 'Hide Template' : 'Show Template')
    }, 100);
  }, [isToggled]);


  const toolbarClick = (args: ClickEventArgs) => {
    let getOrientation = document.getElementsByClassName('orientation')
    let selectedOrientation = getOrientation[0]?.checked ==  true ? 'Portrait':'Landscape'
    
    if ( ProformaInvoiceGrid && args.item.properties.id === 'ProformaInvoiceGrid_PDFExport' ) {
      const withOutHeaderexportProperties: PdfExportProperties = {
    pageOrientation: selectedOrientation,
    fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.pdf`
  };
  ProformaInvoiceGrid.pdfExport(withOutHeaderexportProperties);

    
    }
    if (
      ProformaInvoiceGrid &&
      args.item.properties.id === 'ProformaInvoiceGrid_excelexport'
    ) {
      
      const exportProperties: PdfExportProperties = {
        pageOrientation: selectedOrientation,
        fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.xlsx`
      };
      ProformaInvoiceGrid.excelExport(exportProperties);
    
    }
    if (
      ProformaInvoiceGrid &&
      args.item.properties.id === 'ProformaInvoiceGrid_columnchooser'
    ) {
    }

    if (
      ProformaInvoiceGrid &&
      args.item.properties.id === 'ProformaInvoiceGrid_columnchooser'
    ) {
    }
    if (ProformaInvoiceGrid && args.item.properties.id === 'Delete') {
      console.log('toolbar Delete');
      if (ProformaInvoiceGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', ProformaInvoiceGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`ProductFinishSize`,
          ColumnName:`ProductFinishSizeID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else {
        console.log('delete: ', ProformaInvoiceGrid.getSelectedRecords()[0]?.PIID)
        // deleteDialog.current.hide();
        
        
        PIService.deletePi(ProformaInvoiceGrid.getSelectedRecords()[0]?.PIID).then((res)=>{
      
        let obj={
          TableName:`ProformaInvoice`,
          ColumnName:`PIID`,
          RowID:ProformaInvoiceGrid.getSelectedRecords()[0]?.PIID,
          SelectedRow:true,
          // UserID: userId,

          // DeleteSecurity: `PI${paramsType}Delete`,
          SelectedRowData:ProformaInvoiceGrid.getSelectedRecords()[0]
          // {
          //   FinishDrop: ProformaInvoiceGrid.getSelectedRecords()[0].FinishDrop,
          //   FinishLength: ProformaInvoiceGrid.getSelectedRecords()[0].FinishLength,
          //   FinishSize: ProformaInvoiceGrid.getSelectedRecords()[0].FinishSize,
          //   FinishWidth: ProformaInvoiceGrid.getSelectedRecords()[0].FinishWidth,
          //   ProductDesc: ProformaInvoiceGrid.getSelectedRecords()[0].ProductDesc,
          //   UnitOfMeasurement: ProformaInvoiceGrid.getSelectedRecords()[0].UnitOfMeasurement
          // }
          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
        })
      }
    } 
    if ( 
      ProformaInvoiceGrid &&
      args.item.properties.id === 'clear-filter'
    ) {
      ProformaInvoiceGrid.clearFiltering()
    }
  };

  
  var pathss =   window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');
  useEffect(() => {
  let paths =   window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');
    console.log(paths)
    setParamsType(paths[2])
    InputListSecurity = `PI${paths[2]}List`
    getListService(getpaths[2]);
    SecurityService.getUserAccessList(userId).then((res)=>{
      console.log(res)
      console.log(res.data.recordset[0])
      setPrintedByUserName(res.data.recordset[0].UserName)
      // setPrintedByEmail(res.data.recordset[0].Email)
    });
    SecurityService.getEmployeeEmailByUserID(userId).then((res)=>{
      console.log(res)
      setPrintedByEmail(res.data.recordset[0].EmployeeEmail)
    })

  }, []);

  const updateProformaInvoice = async (id,value) => {
    
    PIService.updatingSp(id,value).then((response) => {
        // console.log('ProductFinishSize get response', response.data.recordset);
        // setDataSourceState(response.data.recordset);
        // getListService()      
        setTimeout(() =>          {
          setPIID = ''
        }, 200);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getListService = async (type = getpaths[2]) => {
    PIService.getList()
    .then(async(response) => {
      console.log('ProductFinishSize get response', response.data.recordset);
        // SaleAgent Filter Code Start
        // let ResponseSaleAgentID = await SecurityService.getSaleAgentIDByUserID(SecurityService.getLoggedInUserId())
        // console.log('ResponseSaleAgentID' ,ResponseSaleAgentID )
        // console.log(ResponseSaleAgentID)
        // let responseData = response.data.recordset.filter((e)=>e.SalesAgentID == ResponseSaleAgentID.data.recordset[0].SaleAgentID)
        // console.log('responseData',responseData)
        // const data = responseData.map((v, k) => {
        //   v.SalesAgentStatusDate =  v.SalesAgentStatusDate == null ? null : new Date(v.SalesAgentStatusDate.split("T", 1)[0]);
        //   v.ETA = v.ETA == null ? null :  new Date(v.ETA.split("T", 1)[0]);
        //   v.ETD =  v.ETD == null ? null : new Date(v.ETD.split("T", 1)[0]);
        //   v.CreationDate = v.CreationDate == null ? null :  new Date(v.CreationDate.split("T", 1)[0]);
        //   v.CRMStatusDate = v.CRMStatusDate == null ? null :  new Date(v.CRMStatusDate.split("T", 1)[0]);
        //   v.PaymentVerificationStatusDate = v.PaymentVerificationStatusDate == null ? null :   new Date(v.PaymentVerificationStatusDate.split("T", 1)[0]);
        //   v.ConfirmConsigneeName = v.ConfirmConsigneeName ? 'Yes' : 'No';
        //   return v;
        // });
        // setDataSourceState(data);
        // SaleAgent Filter Code End




        console.log('getpaths[2]  window' , window.location.pathname
        .substr(1, window.location.pathname.length)
        .split('/')[2] )
        console.log('getpaths[2]' ,getpaths[2] )
        console.log('props.InputType' ,props.InputType )
        if(type  == 'SalesAgent'){
          let ResponseSaleAgentID = await SecurityService.getSaleAgentIDByUserID(SecurityService.getLoggedInUserId())
          console.log('ResponseSaleAgentID' ,ResponseSaleAgentID )
            console.log(ResponseSaleAgentID)
          let responseData = response.data.recordset.filter((e)=>e.SalesAgentID == ResponseSaleAgentID.data.recordset[0].SaleAgentID)
          console.log('responseData',responseData)
          const data = responseData.map((v, k) => {
            v.SalesAgentStatusDate =  v.SalesAgentStatusDate == null ? null : new Date(v.SalesAgentStatusDate.split("T", 1)[0]);
            v.ETA = v.ETA == null ? null :  new Date(v.ETA.split("T", 1)[0]);
            v.ETD =  v.ETD == null ? null : new Date(v.ETD.split("T", 1)[0]);
            v.CreationDate = v.CreationDate == null ? null :  new Date(v.CreationDate.split("T", 1)[0]);
            v.CRMStatusDate = v.CRMStatusDate == null ? null :  new Date(v.CRMStatusDate.split("T", 1)[0]);
            v.PaymentVerificationStatusDate = v.PaymentVerificationStatusDate == null ? null :   new Date(v.PaymentVerificationStatusDate.split("T", 1)[0]);
            v.ConfirmConsigneeName = v.ConfirmConsigneeName ? 'Yes' : 'No';
           return v;
          });
          setDataSourceState(data);
        }else{
          const data = response.data.recordset.map((v, k) => {
            v.SalesAgentStatusDate =  v.SalesAgentStatusDate == null ? null : new Date(v.SalesAgentStatusDate.split("T", 1)[0]);
            v.ETA = v.ETA == null ? null :  new Date(v.ETA.split("T", 1)[0]);
            v.ETD =  v.ETD == null ? null : new Date(v.ETD.split("T", 1)[0]);
            v.CreationDate = v.CreationDate == null ? null :  new Date(v.CreationDate.split("T", 1)[0]);
            v.CRMStatusDate = v.CRMStatusDate == null ? null :  new Date(v.CRMStatusDate.split("T", 1)[0]);
            v.PaymentVerificationStatusDate = v.PaymentVerificationStatusDate == null ? null :   new Date(v.PaymentVerificationStatusDate.split("T", 1)[0]);
            v.ConfirmConsigneeName = v.ConfirmConsigneeName ? 'Yes' : 'No';
           return v;
          });
          setDataSourceState(data);
        }


        setPiisLoading(false)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    {
      // text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess(`PI${paramsType}Delete`)    },
      text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess(`PI${paramsType}Delete`)  },
    'Update',
    'Cancel',
    // {
    //   text: "Clear Filter", tooltipText: "Clear Filter", id: "clear-filter", disabled:false},
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];
   let isAdd = false
  const dialogTemplate = (props) => {
    console.log('props: ', props);
    isAdd = props.isAdd ;
    // editSettings.headerTemplate = `${props.isAdd == true ? '<p>Details Of PI NO</p>':'<p>Add New PI NO</p>'}`;
    return props.isAdd ? (
      <PIAdd dataList={dataSourceState} userName={printedByUserName}   isPreview={false} PIIDGet={getpiid} type={paramsType} popup={isShowPopup} {...props}> </PIAdd>
      
    ) :
      (
        <PIAdd dataList={dataSourceState}  userName={printedByUserName}  isPreview={false} PIIDGet={getpiid}  type={paramsType} popup={isShowPopup} {...props}> </PIAdd>
        // <PIEdit dataList={dataSourceState} {...props}> </PIEdit>

      );
  };
  // const [, isSaveMode] = useState(false);
  var isSaveMode = false
const isShowPopup =  (e)=>{
  setTimeout(() => {
    
    console.log(isSaveMode)

    if(isSaveMode == true  && paramsType != "CRM" ){
      if(e == true){
        setTimeout(() => {
          
      console.log(e)
      setShowDialogOfProduct(true)
      isSaveMode = false
    }, 200);
  }else{
    
    if(setPIID != ''){
      
     updateProformaInvoice(setPIID, 'undefined')
     isSaveMode = false
     
    }
    
  }
  console.log(e)
}else if(isSaveMode == true  && paramsType == "CRM" ){
  // console.log(e)
  // console.log(setPIID)
  // console.log(ProformaInvoiceGrid.dataSource)
  console.log(dataSourceState)

  let getSalesAgentByID =  dataSourceState.filter((res)=>{ 
    console.log(res.PIID)
    console.log(setPIID)
    
    // return res.PIID == setPIID  ? res.SalesAgentStatus : ''
    if(res.PIID == setPIID){ 
    if(res.SalesAgentStatus == 'Release' ||  res.SalesAgentStatus == 'Surrender BL')
    return res.SalesAgentStatus
    }
  })
  // .some((val)=>{
  //   return val.SalesAgentStatus 
  // })
  console.log(getSalesAgentByID)
  console.log(getSalesAgentByID.length > 0 ?  getSalesAgentByID[0].SalesAgentStatus : 'undefined')
  updateProformaInvoice(setPIID, getSalesAgentByID.length > 0 ?  getSalesAgentByID[0].SalesAgentStatus : 'undefined')

}
}, 500);
}
const deleteDialog = useRef();
const [showDialogOfProduct, setShowDialogOfProduct] = useState(false)
const deleteButtons = [
  {
    buttonModel: {
      content: 'Release',
      cssClass: 'e-flat',
      isPrimary: true,
    },
    click: (e) => {
      deleteDialog.current.hide();
      console.log(setPIID)
      if(setPIID != ''){
 
       updateProformaInvoice(setPIID, 'Release')
       setTimeout(() => {
        setPiisLoading(true)
         getListService(getpaths[2])
       }, 200);
      }
      // document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value = 'Release';
      // document.getElementById('ProformaInvoiceDetailGridSalesAgentStatusID').value = '2';
      // setSalesAgentStatus('Release')
      // setSalesAgentStatusID(4)
      // dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 4 ,SalesAgentStatus:'Release' })
      
      // setTimeout(() => {
        // console.log(e);
        
      //   console.log('jd')
      //   // setProductInputValue(undefined)
      //   // document.getElementById('ProductInput').value =''
      //   setProductInputValue('')
      //   setProductDropdownID(0)
      //   // document.getElementById('Product').
      //   // productInput.current.focus()
      // },300);
      // setTimeout(  () => {

      //   let el =  document.getElementById('ProductInput')
      //   // console.(
      //   // productInput.current.focus()
      //   if(el != null){
      //   return el.focus();}

      // },700)
        // console.log(productInput)
        // setProductInputChecker(true)

      // deleteRow();

    },
  },
  {
    buttonModel: {
      content: 'Surrender BL',
      cssClass: 'e-flat',
    },
    click: () => {
      deleteDialog.current.hide();
            if(setPIID != ''){
 
       updateProformaInvoice(setPIID, 'Surrender BL')
       setTimeout(() => {
        setPiisLoading(true)
         getListService(getpaths[2])
       }, 200);
     }
      // document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value = 'Surrender BL';
      // document.getElementById('ProformaInvoiceDetailGridSalesAgentStatusID').value = '2';
      // dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 2 ,SalesAgentStatus:'SalesAgentStatus' })
      // setSalesAgentStatus('Surrender BL')
      // setSalesAgentStatusID(5)
      // dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 5 ,SalesAgentStatus:'Surrender BL' })

      // props.showResult(false)
    },
  },
];
 

const deleteDialogClose = ()=>{
  setShowDialogOfProduct(false)
  // if(isCrm){

  //   setTimeout(() => {
      
  //     document.getElementById('ShortAmount').focus()
  // }, 600);
  //   }else{
  //     setTimeout(() => {
      
  //       // document.getElementById('ShortAmount').focus()
  //       ProformaInvoiceDetailGrid.endEdit()
  //   }, 600);
  //   }
}


  
  const getpiid = (e)=>{

    return setPIID = e
  }
  // const [ConfirmConsigneeCheck, setConfirmConsigneeCheck] = useState(true);
  // const getConfirm = (e)=>{
  //   console.log(e)
  //   setConfirmConsigneeCheck(e)
  // }

  const headerTemplate = (e)=>{
    console.log(e)
    return e.isAdd ? (
      <p>Add New PI </p>
      
    ) :
      (
        <p>Details Of PI </p>
        // <PIEdit dataList={dataSourceState} {...props}> </PIEdit>

      );
        // return `${e.isAdd == true ? '<p>Details Of PI NO</p>':''}`;

  }
  const editSettings: EditSettingsModel = {
    allowEditing: SecurityService.canUserAccess(`PI${paramsType}Edit`),
    allowAdding: SecurityService.canUserAccess(`PI${paramsType}Add`),
    allowDeleting: SecurityService.canUserAccess(`PI${paramsType}Delete`),
    // allowEditing: true,
    // allowAdding: true,
    // allowDeleting: true,
    mode: 'Dialog',
    // addDialogTitle: 'Custom Add Popup Heading' ,
    showDeleteConfirmDialog: true,
    template: dialogTemplate,
    headerTemplate: headerTemplate,
    
    footerTemplate:''
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
    // showClearButton:true
    // ignoreAccent:true
  
    // columns: [
    //   {
    //     field: 'ConfirmConsigneeName',
    //     operator: 'notequal',
    //     value: 'True'
    //   }
    // ]
  };

  var groupOptions: GroupSettingsModel = {
    showDropArea: true,
    columns: []
  };

  
  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userId = SecurityService.getLoggedInUserId();
   
  // console.log(userName)
  var visibleSortPassFields = useRef([defaultSortColumnName])
  var visibleSortPass = useRef([defaultSortColumnType])
  var ctrlPressed =  useRef(false)
  var groupingArray = []
  const actionComplete = (args: DialogEditEventArgs) => {
    console.log('data: ', args);
    ProformaInvoiceGrid?.autoFitColumns();
    if(args.requestType == "grouping"){
      groupingArray.push(args.columnName)
    }
    if(args.requestType == "ungrouping"){
      groupingArray.splice(groupingArray.indexOf(args.columnName),1)
    }
    ctrlPressed.current = false;
    let handleStore = (e) => {
      console.log(e)
       ctrlPressed.current = e.ctrlKey; // Use e.ctrlKey, not e.ctrlkey
      e.stopPropagation();
    };
    document.addEventListener('keydown', handleStore);
    setTimeout(() => {
      if(args.requestType ==  'sorting'){
        console.log(ctrlPressed.current)
        setTimeout(() => {
          if(ctrlPressed.current == true ){
            console.log(ctrlPressed.current)
            if(args.columnName == visibleSortPassFields.current[0] && visibleSortPassFields.current.length == 1){
            }else{
              if(visibleSortPassFields.current.includes(args.columnName)){
                console.log(visibleSortPassFields.current.indexOf(args.columnName))
                visibleSortPass.current[visibleSortPassFields.current.indexOf(args.columnName)] = args.direction;
              }else{
                visibleSortPassFields.current.push(args.columnName)
                visibleSortPass.current.push(args.direction)
              }
            }
            setTimeout(() => {
              return ctrlPressed.current = false;
            }, 200);
          }else{
            console.log(ctrlPressed.current)
            visibleSortPassFields.current =[args.columnName]
            visibleSortPass.current = [args.direction]
          }
        }, 80);
        
        setTimeout(() => {
          console.log('data: ', args);
          console.log(visibleSortPassFields.current)
          console.log(visibleSortPass.current)
          
        }, 300);
          }
        }, 200);
          if(args.requestType == 'filtering'  && args.action == 'filter'){
            let filterArray = [...filterColumnName , args.currentFilteringColumn]
            filterColumnName = filterArray.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    }
    
    if (args?.requestType === 'columnstate') {
      console.log('adjusting column');
      ProformaInvoiceGrid?.autoFitColumns();
    }
    if(args.requestType == 'filtering'  && args.action == "clear-filter"){
      if(recordColumnName.includes(args.currentFilteringColumn)){

        recordColumnName.splice(recordColumnName.indexOf(args.currentFilteringColumn),1)
        
        fiterCheckedValue.splice(recordColumnName.indexOf(args.currentFilteringColumn),1)
        if(filterColumnName.includes(args.currentFilteringColumn) ){
          filterColumnName.splice(filterColumnName.indexOf(args.currentFilteringColumn),1)
        }
      }
   
    }
    if(args.requestType == 'filtering'  && args.action == 'filter'){
      console.log(ProformaInvoiceGrid.getFilteredRecords())
      if(!recordColumnName.includes(args.currentFilteringColumn)){
        const filteredData = ProformaInvoiceGrid.getFilteredRecords();
        let checkedValue   = filteredData.map((val)=>{ return val[args.currentFilteringColumn]}).filter((value, index, self) => { return self.indexOf(value) === index })
        recordColumnName = [...recordColumnName , args.currentFilteringColumn]
        fiterCheckedValue = [...fiterCheckedValue , checkedValue]
        }else{
      
      fiterCheckedValue.splice(recordColumnName.indexOf(args.currentFilteringColumn) , 1)
        const filteredData = ProformaInvoiceGrid.getFilteredRecords();
        let checkedValue   = filteredData.map((val)=>{ return val[args.currentFilteringColumn]}).filter((value, index, self) => { return self.indexOf(value) === index })
        fiterCheckedValue = [...fiterCheckedValue , checkedValue]
        
      } 
  }
    if (args?.requestType === 'columnstate') {
      console.log('adjusting column');
      ProformaInvoiceGrid?.autoFitColumns();
    }
    if (args.form) {
      console.log('data: ', args);
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      }
      if (args.requestType == 'save'){
        isSaveMode = true
        setPiisLoading(true)
        setTimeout(() => {
          console.log(setPIID)
          getListService( getpaths[2])
        }, 2000);
        
      }
      if (args.requestType === 'save'  && args.action   === 'aSSdd') {
        const data = {
          UserId: userId,
          ...args.data,
          ProductCategoryID:1,
          ApproveAbsoluteCommissionAmount:args.data.ApproveAbsoluteCommissionAmount == false? '0': '1', 
          // ConfirmConsigneeName:args.data.ConfirmConsigneeName == false? '0': '1', 
          ConfirmConsigneeName:args.data.ConfirmConsigneeNames , 
          ApproveCommissionAmountPerUnit:args.data.ApproveCommissionAmountPerUnit == false? '0': '1', 
        };
        console.log('args: ', data)
        // delete productFinishSize[0]

      
      PIService.addProductFinishSize(data)
      .then((response) => {
        let AddData = {
          FinishDrop: args.data.FinishDrop,
          FinishLength: args.data.FinishLength,
          FinishSize: args.data.FinishSize,
          FinishWidth: args.data.FinishWidth,
          ProductDesc: args.data.ProductDesc,
          UnitOfMeasurement: args.data.UOM
          
                  }
        AuditTableInput.auditAdd(`ProductFinishSize`,`ProductFinishSize`,`ProductFinishSizeID`,AddData)

        setDataSourceState(response?.data?.recordset)});
  
    }
       
      if (args.requestType === 'save'  && args.action   === 'edit') {
        
        const data = {
          UserID: userId,
          ...args.data,
          PIDate : moment(args.data.PIDate).format('YYYY-MM-DD'),
          ETD: args.data.ETD != undefined ? args.data.ETD : null,	
          ETA: args.data.ETA != undefined ?  args.data.ETA : null,	
          ProductCategoryID:1,
            ApproveAbsoluteCommissionAmount:args.data.ApproveAbsoluteCommissionAmount == false? '0': '1', 
          // ConfirmConsigneeName:args.data.ConfirmConsigneeName == false? 0: 1, 
          ConfirmConsigneeName:args.data.ConfirmConsigneeNames == "true" ? true : false, 
          ApproveCommissionAmountPerUnit:args.data.ApproveCommissionAmountPerUnit == false? '0': '1', 
          ApprovePrimaryCommissionPercentOnAdjustment: '0', 
          ApproveSecondaryCommissionPercentOnAdjustment: '0', 
        };
        console.log('args: ', data)
        PIService.PIEdit(data)
          .then((response) => {
            // let EditData = {
            //   FinishDrop: args.data.FinishDrop,
            //   FinishLength: args.data.FinishLength,
            //   FinishSize: args.data.FinishSize,
            //   FinishWidth: args.data.FinishWidth,
            //   ProductDesc: args.data.ProductDesc,
            //   UnitOfMeasurement: args.data.UnitOfMeasurement
              
            //           }
            //           let totalColumns = Object.keys(EditData);
            //           console.log(totalColumns)
            //           AuditTableInput.auditEdit(totalColumns,selectedRows,EditData,`ProductFinishSize`,args.data.ProductFinishSizeID)
            // getListService();
            console.log(response)});
      }

    }

  };
  

  // exec spProformaInvoiceForList




  
  // const commands: CommandModel[] = [
  //   {  buttonOption: { id: 'email',  content: <span className="e-email"></span> } },
  //   {  buttonOption: { id: 'message',  iconCss: 'e-edit e-icons' } },
  
  // ];
  const [showDialogMessage, setShowDialogMessage] = useState(false);
  const [showDialoguploadPDF, setShowDialoguploadPDF] = useState(false);

  const commandClick = (args: CommandClickEventArgs) => {
    if (args.commandColumn.buttonOption.id === 'email') {
      setShowDialogEmail(false);
      console.log('command click args: ', args.commandColumn.buttonOption.id);
      setSelectedRow(args.rowData);
      setShowDialogEmail(true);
    }
    else if (args.commandColumn.buttonOption.id === 'message') {
      setShowDialogMessage(false);
      console.log('command click args: ', args);
      setSelectedRow(args.rowData);
      setShowDialogMessage(true);
    }
    else if (args.commandColumn.buttonOption.id === 'uploadPDF') {
      setShowDialoguploadPDF(false);
      console.log('command click args: ', args);
      setSelectedRow(args.rowData);
      setShowDialoguploadPDF(true);
    }
  }

  const uploadPDFHeader = () => {
    return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1?'Upload PDF Files.':null}</div>;
  };

  const onClose = ()=>{
    setShowDialoguploadPDF(false)
  }
  const footerTemplate = () => {
    if (showDialoguploadPDF) {
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else {
      <h3 style={{ textAlign: 'center', width: '80%' }}>Conversion Purchase Contract - Add</h3>
      return (<></>);
    }
  };
  const emailHeader = () => {
    return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList')?'Yarn Transactions Import Expenses':null}</div>;
  };

  const contentuploadPDF = () => {
    if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1){
    return (
      <div>
        <UploadPdfFile
        {...selectedRow}
        />
        
      </div>
    )
  }else{
 return( 
    <div className='returnlist'>
      {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
    </div>
 )
  }
  };
  const contentEmailTemplate = () => {
    if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1){
    return (
      <div>
        <EmailEditorPopup
          {...selectedRow}
          loggedInUser={printedByEmail}
          isPreview={false}
        ></EmailEditorPopup>
      </div>
    )
  }else{
 return( 
    <div className='returnlist'>
      {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
    </div>
 )
  }
  };

  const contentMessageTemplate = () => {
    if(SecurityService.canUserAccess('PIMessageList')){
      // console.log(piprops)
    return (
      <div className='dashboard-pi-view'>
      <PIMessageButtonList isDialog={false}  {...selectedRow} />
        

      </div>
    )
  }else{
 return( 
    <div className='returnlist'>
      {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
    </div>
 )
  }
  }; 
  const emailDialogClose = () => {
    this.buttonInstance.style.display = 'inline-block';
    setShowDialogEmail(false);
  };
  const messageDialogClose = () => {
    this.buttonInstance.style.display = 'inline-block';
    setShowDialogMessage(false);
  };
  const uploadPDFDialogClose = () => {
    this.buttonInstance.style.display = 'inline-block';
    setShowDialoguploadPDF(false);
  };
  const actionBegin = (args)=>{
    console.log(args)
  }
  const loadingIndicator = { indicatorType: 'Shimmer' };
  const customPageSizes = [100, 200, 500, 1000, 'All'];


  const getColumns = ()=>{
    let pathss =   window.location.pathname
    .substr(1, window.location.pathname.length)
    .split('/');
    if(pathss[2] == 'CRM'){
      return(
<ColumnsDirective>
<ColumnDirective
    headerText='Commands'
    width='150'
    commands={commands}
    textAlign='center'
    headerTextAlign='center'
/>

<ColumnDirective
    headerText='Sales Agent'
    headerTextAlign='CENTER'
    field='SalesAgentName'
    width='130'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Bank Account'
    field='PaymentReferenceNo'
    width='140'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Sender Name'
    field='PaymentSenderName'
    width='140'
    textAlign='Left'
/>

<ColumnDirective
    headerText='TT Amount'
    field='PaymentDescription'
    width='140'
    textAlign='Right'
/>

<ColumnDirective
    headerText='PI Reference No'
    headerTextAlign='CENTER'
    field='PIReferenceNo'
    width='120'
    textAlign='Left'
/>

<ColumnDirective
    headerText='PI No'
    headerTextAlign='CENTER'
    field='PINo'
    width='110'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Currency'
    headerTextAlign='CENTER'
    field='CurrencyName'
    width='150'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Booking Amount'
    headerTextAlign='CENTER'
    field='BookAmount'
    width='135'
    textAlign='Right'
    format={'n0'}
/>

<ColumnDirective
    headerText='Receipt Amount'
    headerTextAlign='CENTER'
    field='ReceiptAmount'
    width='130'
    textAlign='Right'
    format={'n0'}
/>

<ColumnDirective
    headerText='CRM Status'
    headerTextAlign='CENTER'
    field='CRMStatus'
    width='140'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Consignee'
    headerTextAlign='CENTER'
    field='ConsigneeName'
    width='155'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Trade Board Letter'
    headerTextAlign='CENTER'
    field='TradeBoardLetterStatus'
    width='145'
    textAlign='Left'
/>

<ColumnDirective
    headerText='CRM Status Date'
    headerTextAlign='CENTER'
    field='CRMStatusDate'
    width='145'
    textAlign='Right'
    format={formatOptions}
    type='date'
/>

<ColumnDirective
    headerText='CRM Name'
    headerTextAlign='CENTER'
    field='CRMName'
    width='145'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Serial No'
    headerTextAlign='CENTER'
    field='SerialNo'
    width='90'
    textAlign='Right'
/>

<ColumnDirective
    headerText='Supplier'
    headerTextAlign='CENTER'
    field='SupplierName'
    width='140'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Customer'
    headerTextAlign='CENTER'
    field='CustomerName'
    width='150'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Confirm Consignee'
    headerTextAlign='CENTER'
    field='ConfirmConsigneeName'
    width='155'
    textAlign='Left'
    template={(props) => (
        <div>
            {props.ConfirmConsigneeName}
        </div>
    )}
/>

<ColumnDirective
    headerText='Country'
    headerTextAlign='CENTER'
    field='CountryName'
    width='140'
    textAlign='Left'
/>

<ColumnDirective
    headerText='City'
    headerTextAlign='CENTER'
    field='CityName'
    width='120'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Port'
    headerTextAlign='CENTER'
    field='PortName'
    width='120'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Shipment Type'
    headerTextAlign='CENTER'
    field='ShipmentType'
    width='140'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Adjustment Amount'
    headerTextAlign='CENTER'
    field='AdjustmentAmount'
    width='165'
    textAlign='Right'
    format={'n0'}
/>

<ColumnDirective
    headerText='Purchase Source'
    headerTextAlign='CENTER'
    field='PurchaseSource'
    width='150'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Remarks'
    headerTextAlign='CENTER'
    field='Remarks'
    width='140'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Creation Date'
    headerTextAlign='CENTER'
    field='CreationDate'
    width='140'
    textAlign='Right'
    format={formatOptions}
    type='date'
/>

<ColumnDirective
    headerText='User Name'
    headerTextAlign='CENTER'
    field='UserName'
    width='140'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Vessel Name'
    headerTextAlign='CENTER'
    field='VesselName'
    width='140'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Voyage No'
    headerTextAlign='CENTER'
    field='VoyageNo'
    width='130'
    textAlign='Left'
/>

<ColumnDirective
    headerText='ETD'
    headerTextAlign='CENTER'
    field='ETD'
    width='120'
    textAlign='Right'
    format={formatOptions}
    type='date'
/>

<ColumnDirective
    headerText='ETA'
    headerTextAlign='CENTER'
    field='ETA'
    width='120'
    textAlign='Right'
    format={formatOptions}
    type='date'
/>

<ColumnDirective
    headerText='Short Amount'
    headerTextAlign='CENTER'
    field='ShortAmount'
    width='130'
    textAlign='Right'
    format={'n0'}
/>

<ColumnDirective
    headerText='Sales Agent Status'
    headerTextAlign='CENTER'
    field='SalesAgentStatus'
    width='145'
    textAlign='Left'
/>

<ColumnDirective
    headerText='Sales Agent Status Date'
    headerTextAlign='CENTER'
    field='SalesAgentStatusDate'
    width='140'
    textAlign='Right'
    format={formatOptions}
    type='date'
/>

<ColumnDirective
    headerText='Payment Verification Status'
    headerTextAlign='CENTER'
    field='PaymentVerificationStatus'
    width='175'
    textAlign='Left'
    template={(props) => (
        <div className={props.PaymentVerificationStatus === "PENDING VERIFICATION" ? 'pendingPayment' : ''}>
            {props.PaymentVerificationStatus}
        </div>
    )}
/>

<ColumnDirective
    headerText='Payment Verification Status Date'
    headerTextAlign='CENTER'
    field='PaymentVerificationStatusDate'
    width='175'
    textAlign='Right'
    format={formatOptions}
    type='date'
/>
<ColumnDirective
    headerText='ID'
    headerTextAlign='CENTER'
    field='ProformaInvoicePaymentDetailID'
    width='175'
    textAlign='Center'
    
/>

</ColumnsDirective>)
    }else{
      return(
        <ColumnsDirective>
        <ColumnDirective
                headerText='Commands'
                width='100'
                commands={commands}
                // isFrozen={true}
                // freeze={'left'}
                // lockColumn={true}
                textAlign='center'
                
                headerTextAlign='center'
              
              />
        <ColumnDirective
          headerText='PI No'
          field='PIID'
          width='90'
          headerTextAlign='CENTER'
          textAlign='right'
          visible={false}
        />
        <ColumnDirective
          headerText='Serial No'
          headerTextAlign='CENTER'    
          field='SerialNo'
          width='80'
          // lockColumn={true}
          
          textAlign='Right'
        />
        <ColumnDirective
          headerText='PI No'
          headerTextAlign='CENTER'    
          field='PINo'
          width='110'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='PI Ref No'
          headerTextAlign='CENTER'    
          field='PIReferenceNo'
          width='120'
          textAlign='Left'
        />
        
        <ColumnDirective
          headerText='Supplier '
          headerTextAlign='CENTER'    
          field='SupplierName'
          width='140'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Customer'
          headerTextAlign='CENTER'    
          field='CustomerName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Sales Agent'
          headerTextAlign='CENTER'    
          field='SalesAgentName'
          width='130'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Consignee'
          headerTextAlign='CENTER'    
          field='ConsigneeName'
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Confirm Consignee'
          headerTextAlign='CENTER'    
          field='ConfirmConsigneeName'
          width='155'
          textAlign='Left'
          template={(props) =>{ 
            
            console.log(props)
            return (
            <div>
        {/* {props.ConfirmConsigneeName == true ? "YES" : "NO"} */}
        {props.ConfirmConsigneeName}
            </div>
           
          )}} 
          
        />
        <ColumnDirective
          headerText='Commission Amt Per Unit'
          headerTextAlign='CENTER'    
          field='CommissionAmountPerUnit'
          width='160'
          textAlign='Right'
          format={'n0'}
          visible={false}
          
        />
        <ColumnDirective
          headerText='Approve Commission Amt Per Unit'
          headerTextAlign='CENTER'    
          field='ApproveCommissionAmountPerUnit'
          width='160'
          textAlign='Left'
          visible={false}
        />
        <ColumnDirective
          headerText="Absolute Comm'n Amt"
          headerTextAlign='CENTER'    
          field='AbsoluteCommissionAmount'
          width='160'
          textAlign='Right'
          format={'n0'}
          visible={false}
        />
        <ColumnDirective
          headerText="Approve Absolute Comm'n Amt"
          headerTextAlign='CENTER'    
          field='ApproveAbsoluteCommissionAmount'
          width='155'
          textAlign='Left'
          visible={false}
        />
        <ColumnDirective
          headerText="Country"
          headerTextAlign='CENTER'    
          field='CountryName'
          width='140'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="City"
          headerTextAlign='CENTER'    
          field='CityName'
          width='120'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="Port"
          headerTextAlign='CENTER'    
          field='PortName'
          width='120'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="Shipment Type"
          headerTextAlign='CENTER'    
          field='ShipmentType'
          width='140'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="Book Amount"
          headerTextAlign='CENTER'    
          field='BookAmount'
          width='135'
    
          textAlign='Right'
          format={'n0'}
        />
        <ColumnDirective
          headerText="Currency"
          headerTextAlign='CENTER'    
          field='CurrencyName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="Primary Comm'n % On Adjustment"
          headerTextAlign='CENTER'    
          field='PrimaryCommissionPercentOnAdjustment'
          width='165'
          textAlign='Right'
          format={'n0'}
          visible={false}
        />
        <ColumnDirective
          headerText="Secondary Comm'n % On Adjustment "
          headerTextAlign='CENTER'    
          field='SecondaryCommissionPercentOnAdjustment'
          width='165'
          textAlign='Right'
          format={'n0'}
          visible={false}
        />
        <ColumnDirective
          headerText="Approve Secondary Comm'n % On Adjustment "
          headerTextAlign='CENTER'    
          field='ApproveSecondaryCommissionPercentOnAdjustment'
          width='165'
          textAlign='Left'
          visible={false}
        />
        <ColumnDirective
          headerText="Adjustment Amount"
          headerTextAlign='CENTER'    
          field='AdjustmentAmount'
          width='165'
          textAlign='Right'
          format={'n0'}
        />
        
        { paramsType == "Supervisor" ? <ColumnDirective
          headerText="Base Price Agent"
          headerTextAlign='CENTER'    
          field='BasePriceAgent'
          width='140'
          textAlign='Right'
          format={'n0'}
        /> : null}
        { paramsType == "Supervisor" ? <ColumnDirective
          headerText="Base Price Company"
          headerTextAlign='CENTER'    
          field='BasePriceCompany'
          width='155'
          textAlign='Right'
          format={'n0'}
        /> : null } 
        <ColumnDirective
          headerText="Purchase Source"
          headerTextAlign='CENTER'    
          field='PurchaseSource'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="Trade Board Letter Status"
          headerTextAlign='CENTER'    
          field='TradeBoardLetterStatus'
          width='145'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="Remarks"
          headerTextAlign='CENTER'    
          field='Remarks'
          width='140'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="Creation Date"
          headerTextAlign='CENTER'    
          field='CreationDate'
          width='140'
          textAlign='Right'
          format={formatOptions}
          type='date'
          
        />
        <ColumnDirective
          headerText="User Name"
          headerTextAlign='CENTER'    
          field='UserName'
          width='140'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="Vessel Name"
          headerTextAlign='CENTER'    
          field='VesselName'
          width='140'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="Voyage No"
          headerTextAlign='CENTER'    
          field='VoyageNo'
          width='130'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="ETD"
          headerTextAlign='CENTER'    
          field='ETD'
          width='120'
          textAlign='Right'
          format={formatOptions}
          type='date'
        />
        <ColumnDirective
          headerText="ETA"
          headerTextAlign='CENTER'    
          field='ETA'
          width='120'
          textAlign='Right'
          format={formatOptions}
          type='date'
        />
        <ColumnDirective
          headerText="Receipt Amount"
          headerTextAlign='CENTER'    
          field='ReceiptAmount'
          width='130'
          textAlign='Right'
          format={'n0'}
         
        />
        <ColumnDirective
          headerText="Short Amount"
          headerTextAlign='CENTER'    
          field='ShortAmount'
          width='130'
          textAlign='Right'
          format={'n0'}
        />
        <ColumnDirective
          headerText="UnApproved CRM Amt"
          headerTextAlign='CENTER'    
          field='UnApprovedCRMAmount'
          width='170'
          textAlign='Right'
          visible={false}
          format={'n0'}
        />
        <ColumnDirective
          headerText="Sales Agent Status"
          headerTextAlign='CENTER'    
          field='SalesAgentStatus'
          width='145'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="Sales Agent Status Date"
          headerTextAlign='CENTER'    
          field='SalesAgentStatusDate'
          width='140'
          textAlign='Right'
          format={formatOptions}
          type='date'
        />
        <ColumnDirective
          headerText="Payment
          Verification
          Status"
          headerTextAlign='CENTER'    
          field='PaymentVerificationStatus'
          width='175'
          textAlign='Left'
          template={(props) =>{ 
            
            console.log(props)
            return (
            <div className={props.PaymentVerificationStatus == "PENDING VERIFICATION" ? `pendingPayment` : ''} >
        {props.PaymentVerificationStatus}
            </div>
            // <label className="checkbox-container">

            // <input
            //  type='checkbox'

            //   className={`checkbox ${props.ApprovedProduct ? 'yellow': ''}`}
            //   id={`checkbox_${props.CostingProductRowID}`}
            //    checked={props.ApprovedProduct}
               
            //     onChange={(e) => handleCheckboxChange(e, props)} 
            //   />
            // <span className="checkmark"></span>
            // </label>
          )}} 
        />
        <ColumnDirective
          headerText="Payment 
          Verification 
          Status 
          Date"
          headerTextAlign='CENTER'    
          field='PaymentVerificationStatusDate'
          width='175'
          textAlign='Right'
          format={formatOptions}
          type='date'
        />
        <ColumnDirective
          headerText="CRM Name"
          headerTextAlign='CENTER'    
          field='CRMName'
          width='145'
          textAlign='Left'
        />
        <ColumnDirective
          headerText="CRM Status"
          headerTextAlign='CENTER'    
          field='CRMStatus'
          width='140'
          textAlign='Left'
         
        />
        <ColumnDirective
          headerText="CRM Status Date"
          headerTextAlign='CENTER'    
          field='CRMStatusDate'
          width='145'
          textAlign='Right'
          format={formatOptions}
          type='date'
        />
         </ColumnsDirective>
      )
    }
  }
  const databound = () =>
  {
      if (ProformaInvoiceGrid) {
        ProformaInvoiceGrid.autoFitColumns();
      }
  }
  // useEffect(() => {

  //   if (reduxData.callDataBounds == true) {
  //     document.getElementById('check-checkbox').checked = true
  //     // ProformaInvoiceGrid.dataBound();
  //   }else{

  //     setTimeout(() => {
  //       document.getElementById('check-checkbox').checked = false
        
  //     }, 500);
  //   }
  // }, [reduxData.callDataBounds ]);

  // const databound = () => {
  //   // if (ProformaInvoiceGrid && reduxData.callDataBounds == true) {
  //   if (ProformaInvoiceGrid ) {
  //     ProformaInvoiceGrid.autoFitColumns();
  //   }
  // };
  const ListGridComponent = ({ restrictPageSize = 12 }) => {
    const columns = useMemo(() => {
      console.log(reduxData.gridColumnsListWithDetails.length);
      if (reduxData.gridColumnsListWithDetails.length > 0) {
        return reduxData.gridColumnsListWithDetails.map((field, index) => {
          if (field.field == 'Commands') {
            return (
              <ColumnDirective
                key={index} // You may use index as key here if there is no unique field key available
                headerText={field.headerText}
                width={field.width}
                headerTextAlign={field.headerTextAlign}
                textAlign={field.textAlign}
                {...field.other[0]}
                // direction={visibleSort[index]}
              />
            );
          } else {
          

              return (
                <ColumnDirective
                key={field.field}
                headerText={field.headerText}
                id={field.field}
                field={field.field}
                width={field.width}
                headerTextAlign={field.headerTextAlign}
                textAlign={field.textAlign}
                {...field.other[0]}
                
                // direction={visibleSort[index]}
                />
                );
              
          }
        });
      } else {
        return <ColumnDirective visible={false} />; // You don't need to wrap it with () here
      }
    }, [reduxData.gridColumnsListWithDetails.length > 0]);
    
  return (
      <GridComponent
      ref={(grid) => (ProformaInvoiceGrid = grid)}
      id='ProformaInvoiceGrid'
      dataSource={dataSourceState}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageSizes: customPageSizes ,pageCount: 4, pageSize: 100 }}
      filterSettings={filterOptions}
      
      groupSettings={groupOptions}
      actionComplete={actionComplete}
      allowTextWrap='true'
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      toolbarClick={toolbarClick}
      allowFiltering={true}
      allowGrouping={true}
      showColumnChooser={true}
      allowFreezePane={true}
      allowReordering={true}
      allowResizing={true}
      allowFreeze={true}
      

      // ContextMenuItem={[
      //   `AutoFit`
      // ]}
      
      // frozenColumns={1}
      loadingIndicator={loadingIndicator}
      // freezeMode={true}
      // enableStickyHeader={true}
      enableHover={false} 
      actionBegin={actionBegin}
      sortSettings={reduxData.gridSortingOptionsDetails}
      commandClick={commandClick}
      // dataBound={ databound}
      // contentRender={(args) => {
      //   if (isChecked) {
      //     const table = document.getElementById('ProformaInvoiceGrid_content_table');
      //     if (table) {
      //       table.style.width = 'auto';
      //     }
      //   }
      // }}
      rowDeselecting={
        (args)=>{
            console.log(args)
            if(args.target != null){

              navigator.clipboard.writeText(args.target.innerText)
            }
        }
      }
      rowSelected={
        (args)=>{
          console.log(args)
          if(args.target != null){

            navigator.clipboard.writeText(args.target.innerText)
          }
            selectedRows = args.data
          
                    setTimeout(() => {
            console.log('ss')
          }, 200);
        }
      }
          >
              <ColumnsDirective>
              
            {columns}
              </ColumnsDirective>
              <AggregatesDirective>
              {/* <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='ReceiptAmount'
                    type='Sum'
                    footerTemplate={footerSum}
                  />
                  <AggregateColumnDirective
                    field='ShortAmount'
                    type='Sum'
                    footerTemplate={footerSum}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective> */}
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='BookAmount'
                    type='max'
                    format={'n0'}
                    groupFooterTemplate={(e: any) => {
                      console.log(e)
                      return <span> Max: {e.max}</span>;
                    }
                  }
                  />
                  <AggregateColumnDirective
                    field='ReceiptAmount'
                    type='Sum'
                    format={'n0'}
                    groupFooterTemplate={footerSum}
                  />
                  <AggregateColumnDirective
                    field='ShortAmount'
                    type='Sum'
                    format={'n0'}
                    groupFooterTemplate={footerSum}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>

            <Inject
              services={[
                Edit,
                Toolbar,
                Page,
                Sort,
                PdfExport,
                ExcelExport,
                Filter,
                Group,
                Aggregate,
                Freeze,
                ColumnChooser,
                Reorder,
                Resize,
                CommandColumn,
              ]}
            />
          </GridComponent>
  
  )
  };
  
  const getColumnsDetail = ()=>{
    return ProformaInvoiceGrid.getColumns()
  }
  return (
    (props.isDialog) ? (
      <div>
        
         <div>
                 <DialogComponent
                ref={deleteDialog}
                width={500}
                visible={showDialogOfProduct}
                close={deleteDialogClose}
                content={() => (<div>DO WANT TO TO RELEAES OF SURRENDER BL?</div>)}
                isModal={true}
                header='Confirmation'
                buttons={deleteButtons}
              // footerTemplate={footerTemplate}
              />
              </div>
            <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogEmail}
              close={emailDialogClose}
              //open={openReturnDialog}
              content={contentEmailTemplate}
              isModal={true}
              showCloseIcon={true}
              header={emailHeader}
              height={800}
              zIndex={8958}
              width={1000}
            />
          </div>
          <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogMessage}
              close={messageDialogClose}
              //open={openImportDialog}
              content={contentMessageTemplate}
              isModal={true}
              showCloseIcon={true}
              header={()=>{
              return (<p></p>)}}
              zIndex={8958}

              height={900}
              width={1000}
            />
          </div>
          <div className='App' id='dialog-targets'>
            <DialogComponent
              // width='600px'
              target='#dialog-targets'
              visible={showDialoguploadPDF}
              // close={uploadPDFDialogClose}
              //open={openImportDialog}
              content={contentuploadPDF}
              isModal={true}
              showCloseIcon={true}
              header={uploadPDFHeader}
              footerTemplate={footerTemplate}
              zIndex={8958}
              height={900}
              width={1000}
            />
          </div>
          {showDialogs && (
          <HardDelete
            {...deletingProps}
            showResult={(e) => {
              setShowDialogs(false);
              if (e === false) {
                setShowDialogs(false);
                getListService(getpaths[2]);
              }
            }}
          />
        )}
        <ListGridComponent restrictPageSize={5} />
      </div>
    )
      :
      (
        <div className="content-wrapper">
           <div>
                 <DialogComponent
                ref={deleteDialog}
                width={500}
                visible={showDialogOfProduct}
                close={deleteDialogClose}
                content={() => (<div>DO WANT TO TO RELEAES OF SURRENDER BL?</div>)}
                isModal={true}
                header='Confirmation'
                buttons={deleteButtons}
              // footerTemplate={footerTemplate}
              />
              </div>
              <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogEmail}
              close={emailDialogClose}
              //open={openReturnDialog}
              content={contentEmailTemplate}
              isModal={true}
              showCloseIcon={true}
              header={emailHeader}
              height={900}
              zIndex={8958}
              width={1200}
            />
          </div>
          <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogMessage}
              close={messageDialogClose}
              //open={openImportDialog}
              content={contentMessageTemplate}
              isModal={true}
              showCloseIcon={true}
              header={()=>{
              return (<p></p>)}}
              zIndex={8958}

              height={900}
              width={1000}
            />
          </div>
          <div className='App' id='dialog-targets'>
            <DialogComponent
              // width='600px'
              target='#dialog-targets'
              visible={showDialoguploadPDF}
              // close={uploadPDFDialogClose}
              //open={openImportDialog}
              content={contentuploadPDF}
              isModal={true}
              showCloseIcon={true}
              header={uploadPDFHeader}
              footerTemplate={footerTemplate}

              zIndex={8958}

              height={900}
              width={1000}
            />
          </div>
          <div>
            <div>
              <p className='lists-headings'>PI - List  {/* // DYNAMIC TEMPLATE CODE */}
              <button 
              onClick={(e)=>{ e.preventDefault();setIsToggled(!isToggled)}}
              className={`toggle-templete ${isToggled ? 'active' : ''}`}
              >
       {ToggledValue}               
          </button > </p>

          <TemplateCode
               isToggled={isToggled}
               ColumnProps={ColumnProps}
               getListService={getListService}
               getColumns={getColumnsDetail}
               InputListSecurity={InputListSecurity}
               visibleSortPassFields={visibleSortPassFields}
               visibleSortPass={visibleSortPass}
               groupingArray={groupingArray}
               defaultSortColumnName={defaultSortColumnName}
               defaultSortColumnType={defaultSortColumnType}
               /> 
              {showDialogs && (
          <HardDelete
            {...deletingProps}
            showResult={(e) => {
              setShowDialogs(false);
              if (e === false) {
                setShowDialogs(false);
                getListService(getpaths[2]);
              }
            }}
          />
        )}
              
              <div>
              </div>
              {/* <TextBoxComponent 
              type='checkbox'
              checked={true}
              id='check-checkbox'
              /> */}
              {/* <input type='checkbox' onChange={(e)=>{
                console.log(e)
                if(reduxData.callDataBounds){
                  document.getElementById('check-checkbox').checked = true
                }else{
                  document.getElementById('check-checkbox').checked = false

                }
                // ProformaInvoiceGrid?.autoFitColumns();
              }}  id='check-checkbox' /> */}
              
{ piisLoading == false ?             
  <ListGridComponent /> :
    <div className='loader-div'> <img src={imgSrc} id='' /></div>}
            </div>
          </div>
          
        </div>
      )
  );
}
