import React from 'react';
import {Link} from 'react-router-dom';
import securityService from '../security/security.service';

export default function ProtectedMenu(props)
{        
    console.log('props: ', props);
    // const canAccess = false;
    const canAccess =  securityService.canUserAccess(props?.name);
    // Get All Menu Items
    // If can access then filter 
    
    if(canAccess && props?.link == 'a'){
        return (
            <li className='nav-item'>
            <a href={props.to} className='nav-link'>
              <i className='fas fa-external-link-alt nav-icon' />
              <p>{props.displayName}</p>
            </a>
          </li>
        );
    }else if(canAccess && props?.link != 'a'){
        return (
            <li className='nav-item'>
            <Link to={props.to} className='nav-link'>
              <i className='fas fa-external-link-alt nav-icon' />
              <p>{props.displayName}</p>
            </Link>
          </li>
        );
        
    }
    else {
        return(
            <></>
        )
    }
    
}