import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import ProtectedMenu from '../components/security/protected.menu';
import ProtectedSubmenu from '../components/security/protected.submenu';

export default function Menu() {

  
//   <li className='nav-item'>
//   <ProtectedSubmenu
//     displayValue='Import'
//     names={[
//       `ImportLC`,
//       `ImportExpenses`,
//       `HSCode`,
//       `FullYarnCount`,
//       `YarnBrand`,
//     ]}
//     type='submenu'
//     icon='nav-icon'
//   />
//   <ul className='nav nav-treeview'>
//     <ProtectedMenu
//       to='/Setup/ImportLC'
//       name='ImportLC'
//       displayName='Import LC'
//     />

    
//     <ProtectedMenu
//       to='/Setup/ImportExpenses'
//       name='ImportExpenses'
//       displayName='Import Expenses'
//     />

    

//     <ProtectedMenu
//       to='/Setup/HSCode'
//       name='HSCode'
//       displayName='HS Code'
//     />

    
//   </ul>
// </li>

  useEffect(()=>{
    
    const navItem = document.querySelectorAll('ul .nav-item');
    
    navItem.forEach((va)=>{
      console.log(va.firstChild)
      // classList.contains("myClass")
      if(va.firstChild.classList.contains("nav-treeview")){
        console.log(va)
        va.remove()
      }
    })
   
  },[])

  return (
    <div>
      <aside className='main-sidebar sidebar-light-success elevation-4'>
        <p></p>

        <div className='sidebar'>

          <div className='form-inline'>
            <div className='input-group' data-widget='sidebar-search'>
              <input
                className='form-control form-control-sidebar'
                type='search'
                placeholder='Search'
                aria-label='Search'
              />
              <div className='input-group-append'>
                <button className='btn btn-sidebar'>
                  <i className=' fas fas fa-search fa-fw' />
                </button>
              </div>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className='mt-2'>
            <ul
              className='nav nav-pills nav-sidebar flex-column'
              data-widget='treeview'
              role='menu'
              data-accordion=' fas false'>

 {/* ************** SECURITY MENU SM ***************** */}
 <li className='nav-item'>
                    <ProtectedSubmenu
                      displayValue='Reports'
                      names={[
                    
                        `CustomerLastSale`,
                        `CommissionReport`,
                        `SaleRecord`,
                        `SaleSummary`,
                        `DueDateReport`,
                        `CustomerLedger`,
                        
                      ]}
                      type='mainmenu'
                       icon=' fas fa-bars'
                    />
                    <ul className='nav nav-treeview'>
                    
                  <ProtectedMenu
                    to='/CommissionDetails'
                    name='CommissionReport'
                    displayName='Commission Report'
                  />

                  <ProtectedMenu
                    to='/CustomerLastSale'
                    name='CustomerLastSale'
                    displayName='Customer Last Sale'
                  />


                  <ProtectedMenu
                    to='/SalesRecord'
                    name='SaleRecord'
                    displayName='Sales Record'
                  />
                  
                  <ProtectedMenu
                    to='/SalesSummary'
                    name='SaleSummary'
                    displayName='Sales Summary'
                  />
                  
                  <ProtectedMenu
                    to='/DueDate'
                    name='DueDateReport'
                    displayName='Due Date Report'
                  />

                  <ProtectedMenu
                    to='/Ledger'
                    name='CustomerLedger'
                    displayName='Customer Ledger'
                  />

                  {/* <ProtectedMenu
                    to='/Ledger'
                    name='Ledger'
                    displayName='Ledger'
                  /> */}


                    </ul>
                  </li>
              <li className='nav-item'>



            {/* ************** SETUP MENU SETUPM ***************** */}
            
                <ProtectedSubmenu
                  displayValue='Setup'
                  names={[

                    `City`,
                    `CountryCityPort`,
                    `Country`,
                    `Currency`,
                    `Customer`,
                    `Customer-SalesAgent`,
                    `Designation`,
                    `Employee`,
                    `PaymentType`,
                    `Port`,
                    `PurchaseSource`,
                    `ProcessDesc`,
                    `ShipmentType`,
                    `Supplier`,
                    `TradeBoardLetterStatus`,
                    ]}
                  type='mainmenu'
                  icon=' fas fa-bars'
                />
                <ul className='nav nav-treeview'>
                  {/* <li className='nav-item'>
                    <ul className='nav nav-treeview'> */}
                     <ProtectedMenu
                        to='/Setup/City'
                        name='City'
                        displayName='City'
                      />
                        <ProtectedMenu
                          to='/Setup/CountryCityPort'
                          name='CountryCityPort'
                          displayName='Country City Port'
                        />
                      <ProtectedMenu
                        to='/Setup/Country'
                        name='Country'
                        displayName='Country'
                      />

                      <ProtectedMenu
                        to='/Setup/Currency'
                        name='Currency'
                        displayName='Currency'
                      />

                    <ProtectedMenu
                    to='/Setup/Customer'
                    name='Customer'
                    displayName='Customer'
                    />

                  <ProtectedMenu
                    to='/Setup/CustomerSaleAgent'
                    name='Customer-SalesAgent'
                    displayName='Customer - Sale Agent'
                    />

                      <ProtectedMenu
                        to='/Setup/Designation'
                        name='Designation'
                        displayName='Designation'
                      />
                    <ProtectedMenu
                    to='/Setup/Employee'
                    name='Employee'
                    displayName='Employee'
                    />

                      {/* <li className='nav-item'>
                        <Link to='/Setup/FabricDesign' className='nav-link'>
                          <i className=' fas fas fa-external-link-alt nav-icon' />
                          <p>Fabric Design</p>
                        </Link>
                      </li> */}
                      <ProtectedMenu
                          to='/Setup/PaymentType'
                          name='PaymentType'
                          displayName='Payment Type'
                        />
                      <ProtectedMenu
                        to='/Setup/Port'
                        name='Port'
                        displayName='Port'
                      />

                      <ProtectedMenu
                        to='/Setup/PurchaseSource'
                        name='PurchaseSource'
                        displayName='Purchase Source'
                      />

                      <ProtectedMenu
                        to='/setup/ProcessDesc'
                        name='ProcessDesc'
                        displayName='Process Desc'
                      />

                        <ProtectedMenu
                          to='/Setup/ShipmentType'
                          name='ShipmentType'
                          displayName='Shipment Type'
                        />
                    <ProtectedMenu
                          to='/Setup/Supplier'
                          name='Supplier'
                          displayName='Supplier'
                        />
                      <ProtectedMenu
                        to='/Setup/TradeBoardLetterStatus'
                        name='TradeBoardLetterStatus'
                        displayName='Trade Board Letter Status'
                      />
                 
                    </ul>
                  </li>

            {/* ************** SECURITY MENU SM ***************** */}
              <li className='nav-item'>
                    <ProtectedSubmenu
                      displayValue='Security'
                      names={[
                        `Users`,
                        `ChangeAccess`,
                        `UserReplicate`
                      ]}
                      type='mainmenu'
                       icon=' fas fa-bars'
                    />
                    <ul className='nav nav-treeview'>
                      <ProtectedMenu
                        to='/Security/ChangeAccess'
                        name='ChangeAccess'
                        displayName='Change Access'
                      />
                    <ProtectedMenu
                        to='/Users'
                        name='Users'
                        displayName='Users'
                      />
                    <ProtectedMenu
                        to='/UserReplicate'
                        name='UserReplicate'
                        displayName='User Replicate'
                      />
                       
                    </ul>
                  </li>

              <li className='nav-item'>
            {/* ************** TRANSACTIONS MENU TM ***************** */}
            <ProtectedSubmenu
                  displayValue='Transactions'
                  names={[
                    `PISupervisor`,
                    `PICRM`,
                    `PIMessage`,
                    `PISalesAgent`,
                    `Email`,
                    `UploadSupplier`,
                    `ReceiveUnAllocatedPaymentSalesAgent`,
                    `ReceiveUnAllocatedPaymentCRM`,
                    `TransferUnAllocatedPayment`,
                    `CommissionCalculatorPerUnit`,
                    `AdjustmentCommissionCalculator`,
                  ]}
                  type='mainmenu'
                  icon=' fas fa-bars'
                />
                    <ul className='nav nav-treeview'>

                    <ProtectedMenu
                   to='/Emails'
                   name='Email'
                   displayName='Emails'
                 />
                  {/* <ProtectedMenu
                   to='/Transactions/PI/SalesAgent'
                   name='PISalesAgent'
                   displayName='PI-Sales Agent'
                 /> */}
                  {/* <li className='nav-item'>
                    <Link to='/Transactions/PI' className='nav-link'>
                      <i className=' fas fas fa-external-link-alt nav-icon' />
                      <p>PI</p>
                    </Link>
                  </li>  */}
                   {/* <ProtectedMenu
                    to='/TransactionsSA/PI/SalesAgent'
                    name='PISalesAgent'
                    displayName='PI-Sales Agent'
                  />
                  <ProtectedMenu
                    to='/TransactionsCRM/PI/CRM'
                    name='PICRM'
                    displayName='PI-CRM'
                  />
                  <ProtectedMenu
                    to='/TransactionsSU/PI/Supervisor'
                    name='PISupervisor'
                    displayName='PI-Supervisor'
                  />
 */}


                  <ProtectedMenu
                    to='/Transactions/PI/SalesAgent'
                    name='PISalesAgent'
                    displayName='PI-Sales Agent'
                    link='a'
                  />
                  <ProtectedMenu
                    to='/Transactions/PI/CRM'
                    name='PICRM'
                    displayName='PI-CRM'
                    link='a'
                  />
                  <ProtectedMenu
                    to='/Transactions/PI/Supervisor'
                    name='PISupervisor'
                    displayName='PI-Supervisor'
                    link='a'
                  />

                  {/* <ProtectedMenu
                    to='/Transactions/PI/SalesAgent'
                    name='PISalesAgent'
                    displayName='PI-Sales Agent'
                  />
                  <ProtectedMenu
                    to='/Transactions/PI/CRM'
                    name='PICRM'
                    displayName='PI-CRM'
                  />
                  <ProtectedMenu
                    to='/Transactions/PI/Supervisor'
                    name='PISupervisor'
                    displayName='PI-Supervisor'
                  /> */}

                  <ProtectedMenu
                    to='/PI/Duplicate'
                    name='DuplicatePI'
                    displayName='PI Duplicate'
                  />


                  <ProtectedMenu
                    to='/PI/Message'
                    name='PIMessage'
                    displayName='PI-Message'
                  />
                  
{/*                  
                  <li className='nav-item'>
                    <Link to='/ReceiveUnAllocatedPayment' className='nav-link'>
                      <i className=' fas fas fa-external-link-alt nav-icon' />
                      <p>Receive UnAllocated Payment</p>
                      </Link>
                    </li>  */}
                  <ProtectedMenu
                    to='/ReceiveUnAllocatedPayment/SalesAgent'
                    name='ReceiveUnAllocatedPaymentSalesAgent'
                    displayName='Receive Unallocated Payment - Sales Agent'
                  />
                  <ProtectedMenu
                    to='/ReceiveUnAllocatedPayment/CRM'
                    name='ReceiveUnAllocatedPaymentCRM'
                    displayName='Receive Unallocated Payment - CRM'
                  />
                  
                                 
                  <ProtectedMenu
                    to='/TransferUnAllocatedPayment'
                    name='TransferUnAllocatedPayment'
                    displayName='Transfer UnAllocated Payment'
                  />
                  <ProtectedMenu
                    to='/UploadSupplier'
                    name='UploadSupplier'
                    displayName='Upload Supplier Commission'
                  />
                  <ProtectedMenu
                    to='/CommissionCalculator'
                    name='CommissionCalculatorPerUnit'
                    displayName='Commission Calculator Per Unit'
                  />
                  
                  <ProtectedMenu
                    to='/AdjustmentCalculator'
                    name='AdjustmentCommissionCalculator'
                    displayName='Adjustment Calculator'
                  />


                   {/* <ProtectedMenu
                    to='/AdjustmentCalcultor'
                    name='CommissionCalculatorAdjustment'
                    displayName='Adjustment Calculator'
                  /> */}
                
                 {/* <li className='nav-item'>
                    <Link to='/AdjustmentCalculator' className='nav-link'>
                      <i className=' fas fas fa-external-link-alt nav-icon' />
                      <p>Adjustment Calculator</p>
                    </Link>
                  </li>                  */}
                
                </ul>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
}
